<!--
 * @Author: 蒋威
 * @Date: 2022-05-24 14:50:11
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-06-01 11:31:36
 * @Description: 公司资质
-->
<template>
  <div>
    <div class="qualifications-wrap response" v-if="list.length > 0">
      <div class="topicon-box">
        <img src="@/assets/img/qualifications.png" alt="" />
      </div>
      <h3 class="qualifications-title">{{ title }}</h3>
      <div class="qualifications-wrap">
        <template v-for="(item, index) in list">
          <div class="qualifications-item pointer" :key="index">
            <div class="qualifications-item-info">
              <div class="item-img-box">
                <img v-lazy="item.fileUrl" alt="" />
              </div>
              <h3  class="ellipsis"  :title="item.name ">{{ item.name }}</h3>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 没有数据 -->
    <div class="nodaimg-box" v-if="isNodata && this.list.length < 1">
      <img src="@/assets/img/nodata.png" alt="" />
    </div>
  </div>
</template>

<script>
import { queryByChannelId } from '@/api/news/index.js'
export default {
  name: 'Qualifications',
  data() {
    return {
      title: '公司资质',
      id: '',
      list: [
        // {
        //   title: '教育基金会信息管理系统',
        //   file_url:
        //     'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        // },
      ],
      // 没有数据
      isNodata: false,
    }
  },
  methods: {
    async queryByChannelId() {
      const res = await queryByChannelId({ id: this.id, limit: 99999, page: 1 })
      if (res.code === this.$httpCode) {
        this.list = res.data.company?.list || []
        if (this.list.length < 1) {
          this.isNodata = true
        } else {
          this.isNodata = false
        }
      } else {
        this.list = []
        this.isNodata = true
      }
    },
    init() {
      this.isNodata = false
      this.queryByChannelId()
    },
  },
  created() {
    // this.isNodata = false
    // this.id = this.$route.query.cate_id || 0
    // this.init()
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.qualifications-wrap {
  padding: 70px 0 70px 0;
  .qualifications-title {
    text-align: center;
    height: 42px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 42px;
    color: #202020;
  }
  .topicon-box {
    text-align: center;
    margin-bottom: 50px;
    img {
      width: 118px;
      height: 93px;
    }
  }
  .qualifications-wrap {
    display: flex;
    flex-wrap: wrap;
    .qualifications-item {
      flex: 0 0 calc(33.33% - 80px);
      -ms-flex: 0 0 352px;
      margin-right: 120px;
      margin-top: 60px;
      overflow: hidden;
      .qualifications-item-info {
        width: 100%;
        // width: 320px;
        .item-img-box {
          margin-left: 10px;
          padding: 0 10px;
          width: 100%;
          // width: 300px;
          height: 390px;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          }
          &::after {
            content: '';
            width: 100%;
            height: 0;
            left: 0;
            background-color: rgba(10, 36, 86, 0.4);
            position: absolute;
            top: 0px;
            opacity: 0;
            transition: all 0.3s ease 0s;
          }
        }
        h3 {
          margin-top: 20px;
          width: 100%;
          height: 40px;
          padding: 0 14px;
          background: #f5f5f5;
          //   background: rgba(245, 245, 245, 0.39);
          opacity: 1;
          border-radius: 150px;
          height: 40px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 40px;
          color: #202020;
          text-align: center;
        }
        &:hover {
          .item-img-box {
            img {
              transform: scale(1.1, 1.1);
            }
            &::after {
              opacity: 1;
              height: 390px;
            }
          }
          h3 {
            color: #39957a;
          }
        }
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .qualifications-wrap {
    padding: 70px 0 70px 0;

    .qualifications-wrap {
      .qualifications-item {
        flex: 0 0 48%;
        margin-right: 4%;
        margin-top: 60px;
        .qualifications-item-info {
          width: 100%;
          .item-img-box {
            margin-left: 10px;
            padding: 0 10px;
            width: 100%;
            height: 390px;
            overflow: hidden;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
            }
            &::after {
              content: '';
              width: 100%;
              height: 0;
              left: 0;
              background-color: rgba(10, 36, 86, 0.4);
              position: absolute;
              top: 0px;
              opacity: 0;
              transition: all 0.3s ease 0s;
            }
          }
          h3 {
            margin-top: 20px;
            width: 100%;
            height: $fontSize_28;
            padding: 0 14px;
            background: #f5f5f5;
            //   background: rgba(245, 245, 245, 0.39);
            opacity: 1;
            border-radius: 150px;
            height: $fontSize_28;
            display: flex;
            align-items: center;
            font-size: $fontSize_12;
            line-height: $fontSize_14;
          }
          &:hover {
            .item-img-box {
              img {
                transform: scale(1.1, 1.1);
              }
              &::after {
                opacity: 1;
                height: 390px;
              }
            }
            h3 {
              color: #39957a;
            }
          }
        }
        &:nth-child(3n) {
          margin-right: 4%;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>