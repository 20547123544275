<!--
 * @Author: 蒋威
 * @Date: 2022-05-24 10:54:40
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-07-04 17:31:17
 * @Description: 产品案例
-->
<template>
  <div class="response">
    <div class="productCase-container">
      <div class="productCase-wrap">
        <template v-for="(item, index) in list">
          <div
            class="productCase-item pointer"
            :key="index"
            @click="toDetails(item)"
          >
            <div class="item-img-box">
              <img v-lazy="item.fileUrl" alt="" />
            </div>
            <div class="item-info-box">
              <h3 class="ellipsis" :title="item.title">{{ item.title }}</h3>
              <p class="ellipsis-2">
                {{ item.description }}
              </p>
              <span>{{ $getDate(item.publishTime||item.createdAt, 'YMD') }}</span>
            </div>
          </div>
        </template>
      </div>
      <Page
        :page-size="form.limit"
        :total="total"
        :current-page="form.page"
        @currentChange="currentChange"
        v-if="totalPage > 1"
      ></Page>
      <div v-if="totalPage == 1" class="loadcomplete">没有更多了~</div>
      <div class="nodaimg-box" v-if="isNodata">
        <img src="@/assets/img/nodata.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { detailsByNewsId, queryByChannelId } from '@/api/news/index.js'
export default {
  name: 'ProductCase',
  props: {
    // id: {
    //   type: String | Array,
    // },
  },
  data() {
    return {
      list: [],
      id: '',
      total: 0,
      totalPage: 0,
      form: {
        limit: 12,
        page: 1,
      },
      isNodata: false,
      // 是否是返回页面 ，如果是 执行mounted里面的方法
      isGoback: true,
    }
  },
  methods: {
    toDetails(item) {
      this.$emit('toDetail', item)
    },
    rest() {
      this.isNodata = false
      this.total = 0
      this.totalPage = 0
      this.form = {
        limit: 12,
        page: 1,
      }
      this.queryByChannelId()
    },
    async queryByChannelId() {
      const res = await queryByChannelId({ id: this.id, ...this.form })

      if (res.code === this.$httpCode) {
        this.list = res.data.newsPage?.list || []
        this.total = res.data.newsPage?.totalCount || 0
        this.totalPage = res.data.newsPage?.totalPage || 0
        if (this.total == 0) {
          this.isNodata = true
        } else {
          this.isNodata = false
        }
      } else {
        this.isNodata = true
        this.list = []
        this.total = 0
        this.totalPage = 0
      }
    },
    currentChange(val) {
      this.form.page = val
      this.queryByChannelId()
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isGoback) {
        this.isNodata = false
        this.id = this.$route.query.cate_id || 0
        this.rest()
      }
    })
  },
}
</script>

<style  lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.productCase-container {
  padding: 30px 0 80px 0;
  .productCase-wrap {
    display: flex;
    flex-wrap: wrap;
    .productCase-item {
      height: 458px;
      margin-right: 35px;
      // flex: 0 0 410px;
      flex: 0 0 calc(33% - 23px);
      -ms-flex: 0 0 410px;
      margin-bottom: 20px;
      background: #f8f8f8;
      overflow: hidden;
      position: relative;
      .item-img-box {
        // width: 410px;
        width: 100%;
        height: 256px;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        }
      }
      .item-info-box {
        padding: 30px 20px;
        h3 {
          height: 48px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 24px;
          color: #202020;
        }
        p {
          font-size: 16px;
          height: 48px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 24px;
          color: #666666;
          margin-bottom: 6px;
        }
        span {
          display: inline-block;
          margin-top: 24px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 22px;
          color: #666666;
        }
      }
      &::after {
        content: '';
        width: 100%;
        height: 0;
        background-color: rgba(10, 36, 86, 0.4);
        position: absolute;
        top: 0px;
        opacity: 0;
        transition: all 0.3s ease 0s;
        display: none;
      }
      &:hover {
        box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
        img {
          transform: scale(1.1, 1.1);
        }
        .item-info-box {
          h3 {
            color: #39957a;
          }
          p {
          }
          span {
          }
        }
        &::after {
          opacity: 1;
          height: 256px;
        }
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
@media only screen and (min-width: 1080px) and (max-width: 1299px) {
  .productCase-container {
    padding: 30px 0 80px 0;
    .productCase-wrap {
      display: flex;
      flex-wrap: wrap;
      .productCase-item {
        height: auto;
        margin-right: 2%;
        // flex: 0 0 410px;
        flex: 0 0 32%;
        -ms-flex: 0 0 32%;
        margin-bottom: 20px;
        background: #f8f8f8;
        overflow: hidden;
        position: relative;
        .item-img-box {
          width: 100%;
          height: auto;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .item-info-box {
          padding: 20px 20px;
          h3 {
            height: 48px;
            font-size: 18px;
            line-height: 24px;
          }
          p {
            font-size: 16px;
            height: 48px;
            line-height: 24px;
            margin-bottom: 6px;
          }
          span {
            display: inline-block;
            margin-top: 24px;
            font-size: 16px;
            line-height: 22px;
          }
        }
        &:hover {
          box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
          img {
            transform: scale(1.1, 1.1);
          }
          .item-info-box {
            h3 {
              color: #39957a;
            }
            p {
            }
            span {
            }
          }
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
@media only screen and (min-width: 941px) and (max-width: 1079px) {
  .productCase-container {
    padding: 30px 0 80px 0;
    .productCase-wrap {
      display: flex;
      flex-wrap: wrap;
      .productCase-item {
        height: auto;
        margin-right: 2%;
        // flex: 0 0 410px;
        flex: 0 0 32%;
        -ms-flex: 0 0 32%;
        margin-bottom: 20px;
        background: #f8f8f8;
        overflow: hidden;
        position: relative;
        .item-img-box {
          width: 100%;
          height: auto;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .item-info-box {
          padding: 30px 20px;
          h3 {
            height: 48px;
            font-size: 18px;
            line-height: 24px;
          }
          p {
            font-size: 16px;
            height: 48px;
            line-height: 24px;
            margin-bottom: 6px;
          }
          span {
            display: inline-block;
            margin-top: 24px;
            font-size: 16px;
            line-height: 22px;
          }
        }
        &:hover {
          box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
          img {
            transform: scale(1.1, 1.1);
          }
          .item-info-box {
            h3 {
              color: #39957a;
            }
            p {
            }
            span {
            }
          }
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 940px) {
  .productCase-container {
    padding: 60px 0 80px 0;
    .productCase-wrap {
      display: flex;
      flex-wrap: wrap;
      .productCase-item {
        height: auto;
        margin-right: 4%;
        // flex: 0 0 410px;
        flex: 0 0 48%;
        -ms-flex: 0 0 48%;
        margin-bottom: 4%;
        background: #f8f8f8;
        overflow: hidden;
        position: relative;
        .item-img-box {
          // width: 410px;
          width: 100%;
          height: auto;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          }
        }
        .item-info-box {
          padding: 20px 20px;
          h3 {
            height: 28px;
            font-size: 16px;
            line-height: 20px;
          }
          p {
            font-size: 14px;
            height: 36px;
            line-height: 18px;
            margin-bottom: 10px;
          }
          span {
            margin-top: 10px;
            font-size: 14px;
            line-height: 18px;
          }
        }
        &:hover {
          box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
          img {
            transform: scale(1.1, 1.1);
          }
          &::after {
            opacity: 1;
            height: 15.6 * $fontSize_10;
          }
        }
        &:nth-child(3n) {
          margin-right: 4%;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px){
  .productCase-container {
    padding: 60px 0 80px 0;
    .productCase-wrap {
      display: flex;
      flex-wrap: wrap;
      .productCase-item {
        height: auto;
        margin-right: 4%;
        // flex: 0 0 410px;
        flex: 0 0 48%;
        -ms-flex: 0 0 48%;
        margin-bottom: 4%;
        background: #f8f8f8;
        overflow: hidden;
        position: relative;
        .item-img-box {
          // width: 410px;
          width: 100%;
          height: auto;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          }
        }
        .item-info-box {
          padding: 10px 10px;
          h3 {
            height: 28px;
            font-size: 16px;
            line-height: 20px;
          }
          p {
            font-size: 14px;
            height: 36px;
            line-height: 18px;
            margin-bottom: 10px;
          }
          span {
            margin-top: 4px;
            font-size: 14px;
            line-height: 18px;
          }
        }
        &:hover {
          box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
          img {
            transform: scale(1.1, 1.1);
          }
          &::after {
            opacity: 1;
            height: 15.6 * $fontSize_10;
          }
        }
        &:nth-child(3n) {
          margin-right: 4%;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>