<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 16:44:10
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-09 10:09:53
 * @Description: 富文本详情
-->
<template>
  <div>
    <div class="response richText-container" v-if="!isNodata">
      <div v-html="$getHtml(article.content)" class="htmlBox"></div>
    </div>
    <ServeList ref="richServeListRef" v-if="!isNodata" />
    <div class="nodaimg-box" v-if="isNodata">
      <img src="@/assets/img/nodata.png" alt="" />
    </div>
  </div>
</template>

<script>
import ServeList from '@/components/ServeList'
import { detailsByNewsId } from '@/api/news/index.js'

export default {
  props: {
    id: {
      type: String | Array,
    },
  },
  components: {
    ServeList,
  },
  data() {
    return {
      article: {},
      isNodata: false,
    }
  },
  methods: {
    // 自己根据id 获取数据
    async init() {
      this.isNodata = false
      const res = await detailsByNewsId(this.id)
      if (res.code === this.$httpCode) {
        // 如果数据不存在
        if (!res.data.newsDetails && !res.data.serviceList) {
          this.isNodata = true
          return
        }
        this.article = res.data.newsDetails
        this.$nextTick(() => {
          this.$refs.richServeListRef.list = res.data.serviceList || []
        })
      } else {
        this.isNodata = true
      }
    },
    // 获取从父组件传递来的数据
    setInit(data) {
      this.$nextTick(() => {
        this.article = data.data.newsContent || {}
        const list = data.data.serviceList || []
        this.$refs.richServeListRef.list = list
        if (Object.keys(this.article).length < 1 && list.length < 1) {
          this.isNodata = true
        } else {
          this.isNodata = false
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.richText-container {
  padding: 60px 60px;
}
.nodaimg-box {
  padding-top: 20px;
}
.htmlBox {
  ::v-deep p {
    line-height: 32px;
    margin: 20px 0;
  }
  ::v-deep span {
    line-height: 28px;
  }  
  ::v-deep li {
      line-height: 28px;
    }
}
@media only screen and (max-width: 940px) {
  .richText-container {
    padding: 40px 40px;
  }
  .htmlBox {
  ::v-deep p {
    line-height: $fontSize_24;
    margin: 20px 0;
  }
  ::v-deep span {
    line-height: $fontSize_24;
  }
  ::v-deep li {
    line-height: $fontSize_24;
  }
}
}
@media only screen and (max-width: 940px) {
  .richText-container {
    padding: 20px 20px;
  }
}
</style>