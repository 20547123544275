<!--
 * @Author: 蒋威
 * @Date: 2022-05-24 15:30:57
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-08 11:00:57
 * @Description: 联系我们 ContactUs
-->
<template>
  <div class="response contactUs-container">
    <div class="topicon-box">
      <img src="@/assets/img/contactUs.png" alt="" />
    </div>
    <h3 class="contactUs-title">{{ title }}</h3>
    <div class="contactUs-wrap">
      <div class="contactUs-item">
        <div class="icon-box">
          <i class="iconfont icon-wodekefu"></i>
        </div>
        <p class="contactUs-lable">联系电话</p>
        <p class="contactUs-text ellipsis-4">
          {{ contactUs.phone }}
        </p>
      </div>
      <div class="contactUs-item">
        <div class="icon-box">
          <i class="iconfont icon-youxiang"></i>
        </div>
        <p class="contactUs-lable">联系邮箱</p>
        <p class="contactUs-text ellipsis-4">
          {{ contactUs.mail }}
        </p>
      </div>
      <div class="contactUs-item">
        <div class="icon-box">
          <i class="iconfont icon-weizhi1"></i>
        </div>
        <p class="contactUs-lable">公司地址</p>
        <p class="contactUs-text ellipsis-4">
          {{ contactUs.provinceName }}{{ contactUs.cityName }}{{ contactUs.areaName }}{{ contactUs.address }}
        </p>
      </div>
      <div class="contactUs-item qrCode-item">
        <div class="icon-box">
          <i class="iconfont icon-erweima1"></i>
        </div>
        <p class="contactUs-lable qrCode-lable">公司公众号</p>
        <div class="qrCode-box">
          <img v-lazy="contactUs.qrCodeId" alt="" />
        </div>
      </div>
    </div>
    <div class="amap" ref="mapcontainer"></div>
    <!-- <RichText style="padding: 0" /> -->
  </div>
</template>

<script>
import RichText from '@/components/RichText.vue'
export default {
  name: 'ContactUs',
  components: {
    RichText,
  },
  data() {
    return {
      title: '联系我们',
      contactUs: {
        phone: '',
        mail: '',
        addr: '',
        qrCodeIdUrl: '',
      },
    }
  },
  mounted() {},
  created() {
    if (window.AMap) return
    var url =
      'https://webapi.amap.com/maps?v=1.4.15&key=c99506737669eec301d166f9118961d3&callback=onLoad'
    var jsapi = document.createElement('script')
    jsapi.charset = 'utf-8'
    jsapi.src = url
    document.head.appendChild(jsapi)
  },
  methods: {
    init() {
      if (window.AMap) {
        this.setMap()
      } else {
        window.onLoad = () => {
          if (window.AMap) {
            this.setMap()
          }
        }
      }
    },
    setMap() {
      this.$nextTick(() => {
        const map = new window.AMap.Map(this.$refs.mapcontainer, {
          resizeEnable: true,
          zoom: 20,
          center: [
            this.contactUs.lng || 112.908559,
            this.contactUs.lat || 28.223802,
          ], //默认的地图中心经纬度
        })
        var marker = new window.AMap.Marker({
          position: new window.AMap.LngLat( this.contactUs.lng || 112.908559,  this.contactUs.lat || 28.223802,),
          // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        })
        map.add(marker)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.contactUs-container {
  padding: 70px 0;
  .contactUs-title {
    text-align: center;
    height: 42px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 42px;
    color: #202020;
  }
  .topicon-box {
    text-align: center;
    margin-bottom: 50px;
    img {
      width: 117px;
      height: 102px;
    }
  }
  .contactUs-wrap {
    margin-top: 80px;
    display: flex;
    margin-bottom: 30px;
    .contactUs-item {
      padding: 40px 40px 0px 40px;
      height: 240px;
      background: rgba(245, 245, 245, 0.39);
      border: 1px solid #ffffff;
      opacity: 1;
      
      // width: 305px;
      flex: 0 0 calc(25% - 20px);
      -ms-flex: 0 0 225px;
      margin-right: 26px;
      text-align: center;
      .icon-box {
        width: 44px;
        height: 44px;
        display: inline-block;
        border: 2px solid #39957a;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        i {
          color: #39957a;
          font-size: 22px;
        }
      }
      .contactUs-lable {
        margin-top: 16px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 20px;
        height: 40px;
      }
      .qrCode-lable{
        height: 20px;
      }
      .contactUs-text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
      }
      .qrCode-box {
        margin-top: 16px;
        img {
          width: 90px;
          height: 90px;
        }
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
.amap {
  width: 100%;
  height: 540px;
}

@media only screen and (max-width: 940px) {.contactUs-container {
  padding: 60px 0;
  .contactUs-wrap {
    margin-top: 80px;
    display: flex;
    margin-bottom: 30px;
    flex-wrap: wrap;
    .contactUs-item {
      padding: 24px 20px 0px 20px;
      margin-bottom: 30px;
      height:  14*$fontSize_10 ;
      background: rgba(245, 245, 245, 0.39);
      border: 1px solid #ffffff;
      opacity: 1;
      
      // width: 305px;
      flex: 0 0 48%;
      -ms-flex: 0 0 43%;
      margin-right: 4%;
      text-align: center;
      .icon-box {
        width:$fontSize_24;
        height:$fontSize_24;
        display: inline-block;
        border: 2px solid #39957a;
        border-radius: 50%;
        text-align: center;
        line-height: $fontSize_24;
        i {
          color: #39957a;
          font-size: $fontSize_16;
        }
      }
      .contactUs-lable {
        margin-top: 16px;
        font-size: $fontSize_12;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: $fontSize_14;
        height: $fontSize_20;
        &.qrCode-lable{
          height: $fontSize_14;
          margin-top: 6px;
        }
      }
      .contactUs-text {
        font-size: $fontSize_12;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: $fontSize_14;
        color: #666666;
      }
      .qrCode-box {
        margin-top: 4px;
        img {
          width:  7.6*$fontSize_10;
          height: 7.6*$fontSize_10;
        }
      }
      &.qrCode-item {
      padding-top: 12px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
  
}
</style>