<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 08:55:51
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-06-07 08:31:09
 * @Description: 新闻
-->

<template>
  <div>
    <Banner :bannerList="bannerList"></Banner>
    <NavBox
      v-if="!this.$route.query.id"
      @changeMenu="changeMenu"
      :menuList="menuList"
      ref="NavBoxRef"
    />
    <Breadcrumb
      ref="Breadcrumb"
      v-if="this.$route.name != 'home' && this.$route.query.id"
      :firstMenu="firstMenu"
      :currentMenu="currentMenu"
    />
    <!-- 产品列表 -->
    <!-- <template  v-if="model == 'news' && ['list', 'all'].includes(menuType)"> -->
    <template
      v-if="
        !this.$route.query.id &&
        model == 'news' &&
        showModel == 1 &&
        ['list', 'all'].includes(menuType)
      "
    >
      <ProductList ref="productListRef" @toDetail="toDetail" />
    </template>

    <!-- 产品详情 -->
    <!-- <template
      v-if="
        this.$route.query.id &&
        model == 'news' &&
        showModel == 1 &&
        ['list', 'all'].includes(menuType)
      "
    >
      <RichText ref="productRichText" :id="detailsId" />
    </template> -->
    <!-- 服务 -->
    <!-- <template v-if="model == 'news' && showModel == 1 && menuType == 'info'">
      <Service ref="serviceRef"  :id="detailsId" />
    </template> -->

    <!-- 产品案例 -->
    <template
      v-if="
        !this.$route.query.id &&
        model == 'news' &&
        showModel == 2 &&
        ['list', 'all'].includes(menuType)
      "
    >
      <ProductCase ref="productCaseRef" @toDetail="toDetail" />
    </template>
    <!-- -->

    <!-- 案列、公司资讯 列表里面的详情 -->
    <template
      v-if="
        this.$route.query.id &&
        model == 'news' &&
        [1, 2, 3].includes(showModel) &&
        ['list', 'all'].includes(menuType)
      "
    >
      <RichText ref="productRichText" :id="detailsId" />
    </template>

    <!--服务 产品案例 单个案例 公司资讯 的单个内容-->
    <template
      v-if="
        !this.$route.query.id &&
        model == 'news' &&
        [1, 2, 3].includes(showModel) &&
        ['info'].includes(menuType)
      "
    >
      <ProductDetails ref="ProductCaseinfoRef" :id="detailsId" />
    </template>
    <!-- 如果query.id存在 且type=info 说明用户手动改变了cate_id -->
    <template
      v-if="
        this.$route.query.id &&
        model == 'news' &&
        [1, 2, 3].includes(showModel) &&
        ['info'].includes(menuType)
      "
    >
      <div class="nodaimg-box">
        <img src="@/assets/img/nodata.png" alt="" />
      </div>
    </template>
    <!-- 公司资讯 -->
    <template
      v-if="
        !this.$route.query.id &&
        model == 'news' &&
        showModel == 3 &&
        ['list', 'all'].includes(menuType)
      "
    >
      <RealtimeInfo ref="RealtimeInfoRef" @toDetail="toDetail" />
    </template>

    <!-- 关于我们 -->
    <!-- 使用富文本 -->
    <!-- <RichText />  -->

    <!-- 公司资质 -->
    <template v-if="model == 'other' && ['company'].includes(menuType)">
      <Qualifications ref="qualificationsRef" />
    </template>

    <!-- 联系我们 -->
    <template v-if="model == 'other' && ['contactUs'].includes(menuType)">
      <ContactUs ref="contactUsRef" />
    </template>

    <!-- 合作伙伴 -->
    <template v-if="model == 'other' && ['partner'].includes(menuType)">
      <CooperativePartner ref="cooperativePartnerRef" />
    </template>

    <!-- 没有数据 -->
    <div class="nodaimg-box" v-if="isNodata">
      <img src="@/assets/img/nodata.png" alt="" />
    </div>
  </div>
</template>

<script>
import ProductList from '@/components/ProductList.vue'
import RichText from '@/components/RichText.vue'
import Service from '@/components/Service.vue'
import ProductCase from '@/components/ProductCase.vue'
import ProductDetails from '@/components/ProductDetails.vue'
import RealtimeInfo from '@/components/RealtimeInfo.vue'
import Qualifications from '@/components/Qualifications.vue'
import ContactUs from '@/components/ContactUs.vue'
import CooperativePartner from '@/components/CooperativePartner.vue'
import { mapGetters } from 'vuex'
import { channelById } from '@/api/menu/index.js'
import { queryByChannelId } from '@/api/news/index.js'
import { websiteAds_list } from '@/api/homePage/index.js'
export default {
  components: {
    ProductList,
    RichText,
    Service,
    ProductCase,
    ProductDetails,
    RealtimeInfo,
    Qualifications,
    ContactUs,
    CooperativePartner,
  },

  inject: ['reload'],
  computed: {
    ...mapGetters(['menu']),
  },
  data() {
    return {
      detailsId: '',
      // 一级菜单
      firstMenu: {},
      // 当前选择菜单
      currentMenu: {},
      menuList: [],
      menuType: null,
      model: null,
      showModel: null,
      bannerList: [
        // {
        //   file_url:
        //     'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        // },
      ],
      // 没有数据
      isNodata: false,
    }
  },
  methods: {
    // 获取轮播
    async getBannerList() {
      const res = await websiteAds_list({
        banner_type: 2,
        page: 1,
        limit: 1,
        column_id: this.$route.query.moduleId || this.firstMenu.id,
      })
      if (res.code === this.$httpCode) {
        this.bannerList = res.data.list && res.data.list.slice(0, 5)
      }
    },
    changeMenu(item) {
      if (item.model == 'url' && item.url) {
        window.open(item.url, '_blank')
        return
      }
      const name = this.getRouterName(item.model)
      let query
      if (name == 'home') {
        query = {}
      } else {
        query = {
          cate_id: item.id,
          moduleId: this.$route.query.moduleId,
          // moduleId: this.$route.query.moduleId||localStorage.getItem('moduleId'),
        }
      }
      this.reload()
      this.$router.push({
        name: name,
        query: query,
      })
    },
    // 根据模型匹配路由名称
    getRouterName(model) {
      let name = 'home'
      switch (model) {
        case 'home':
          name = 'home'
          break
        case 'news':
          name = 'news'
          break
        // 产品案例
        case 'other':
          name = 'news'
          break
        case 'url':
          name = 'url'
          break
        default:
          name = 'home'
          break
      }
      return name
    },
    async init() {
      this.isNodata = false
      const res = await channelById({
        id: this.$route.query.moduleId,
        diff:'web'
      })
      let res1
      if (res.code === this.$httpCode) {
        // 设置选中的一级菜单
        this.firstMenu = res.data.WebsiteChannel || {}
        this.menuList = res.data.websiteChannelVoList || []
        let parms = {}
        // 如果有二级菜单
        if (this.$route.query.cate_id || this.menuList.length > 0) {
          parms = {
            id: this.$route.query.cate_id || this.menuList[0].id,
            limit: 10,
            page: 1,
          }
        } else {
          parms = { id: this.$route.query.moduleId, limit: 10, page: 1 }
        }
        // 二级菜单选中模型
        res1 = await queryByChannelId(parms)
        if (res1.code === this.$httpCode) {
          this.currentMenu = res1.data.websiteChannel || {}
        } else {
          this.currentMenu = {}
          if (!this.$route.query.id) {
            this.isNodata = true
          }
          return
        }
        this.menuType = this.currentMenu.type
        this.showModel = this.currentMenu.showModel
        this.model = this.currentMenu.model
        this.getBannerList()
        if (Object.keys(res1.data).length == 0) {
          this.isNodata = true
          return
        }
        // 初始化数据 不是列表 里面的详情
        if (!this.$route.query.id) {
          this.queryByChannelId(res1, parms)
        }
      } else {
        this.firstMenu = {}
      }
      // 列表里面的详情
      if (this.$route.query.id && this.model == 'news') {
        switch (this.showModel) {
          // 产品服务详情
          case 1:
            this.detailsId = this.$route.query.id
            this.$nextTick(() => {
              this.$refs.Breadcrumb && this.$refs.Breadcrumb.setMenu()
              this.$refs.productRichText && this.$refs.productRichText.init()
            })
            break
          //  客户 案例详情
          case 2:
            this.detailsId = this.$route.query.id
            this.$nextTick(() => {
              this.$refs.Breadcrumb && this.$refs.Breadcrumb.setMenu()
              this.$refs.productRichText && this.$refs.productRichText.init()
            })
            break
          // 资讯详情
          case 3:
            this.detailsId = this.$route.query.id
            this.$nextTick(() => {
              this.$refs.Breadcrumb && this.$refs.Breadcrumb.setMenu()
              this.$refs.productRichText && this.$refs.productRichText.init()
            })
            break
          default:
            break
        }
      }
    },
    async queryByChannelId(data, parms) {
      // 如果menuType = info 直接使用data 赋值即可
      let res
      if (this.model == 'news') {
        if (this.showModel) {
          switch (this.showModel) {
            case 1:
              // 产品及服务
              switch (this.menuType) {
                case 'list':
                case 'all':
                  this.$nextTick(() => {
                    if (this.$refs.productListRef) {
                      this.$refs.productListRef.id = parms.id
                      this.$refs.productListRef.isGoback = false
                      this.$refs.productListRef.rest()
                      // this.$refs.productListRef.list = data.data.newsPage.list
                    }
                  })
                  break
                case 'info':
                  this.$nextTick(() => {
                    if (this.$refs.ProductCaseinfoRef) {
                      this.$refs.ProductCaseinfoRef.init(data)
                    }
                  })
                  break
              }
              break
            case 2:
              // 产品案例
              switch (this.menuType) {
                case 'list':
                case 'all':
                  this.$nextTick(() => {
                    if (this.$refs.productCaseRef) {
                      this.$refs.productCaseRef.id = parms.id
                      this.$refs.productCaseRef.isGoback = false
                      this.$refs.productCaseRef.rest()
                      // this.$refs.productCaseRef.list = data.data.newsPage.list
                    }
                  })
                  break
                case 'info':
                  this.$nextTick(() => {
                    if (this.$refs.ProductCaseinfoRef) {
                      this.$refs.ProductCaseinfoRef.init(data)
                    }
                  })
                  break
              }
              break
            case 3:
              // 公司资讯
              switch (this.menuType) {
                case 'list':
                case 'all':
                  this.$nextTick(() => {
                    if (this.$refs.RealtimeInfoRef) {
                      this.$refs.RealtimeInfoRef.id = parms.id
                      this.$refs.RealtimeInfoRef.isGoback = false
                      this.$refs.RealtimeInfoRef.rest()
                      // this.$refs.RealtimeInfo.list = data.data.newsPage.list
                    }
                  })
                  break
                case 'info':
                  this.$nextTick(() => {
                    if (this.$refs.ProductCaseinfoRef) {
                      this.$refs.ProductCaseinfoRef.init(data)
                    }
                  })
                  break
              }
              break
            default:
              break
          }
        }
      } else if (this.model == 'other') {
        switch (this.menuType) {
          // 公司资质
          case 'company':
            this.$nextTick(() => {
              if (this.$refs.qualificationsRef) {
                this.$refs.qualificationsRef.title =
                  data.data.websiteChannel.title
                this.$refs.qualificationsRef.id = parms.id
                this.$refs.qualificationsRef.init()
              }
            })
            break
          // 联系我们
          case 'contactUs':
            this.$nextTick(() => {
              if (this.$refs.contactUsRef) {
                this.$refs.contactUsRef.title = data.data.websiteChannel.title
                this.$refs.contactUsRef.contactUs = data.data.contactUs
                this.$refs.contactUsRef.init()
              }
            })
            break
          // 合作伙伴
          case 'partner':
            this.$nextTick(() => {
              if (this.$refs.cooperativePartnerRef) {
                this.$refs.cooperativePartnerRef.title =
                  data.data.websiteChannel.title
                this.$refs.cooperativePartnerRef.id = parms.id
                this.$refs.cooperativePartnerRef.init()
              }
            })
            break

          default:
            break
        }
      }
    },
    /**
     * @Auth: 蒋威
     * @Date: 2022-05-25 17:14:02
     * @Description: 去产品详情、案例详情、资讯详情
     */
    toDetail(item) {
      localStorage.setItem('dcrumbName', item.title)
      const name = this.getRouterName(this.currentMenu.model)
      let query = {}
      if (name == 'home') {
        query = {}
      } else {
        query = {
          cate_id: this.currentMenu.id,
          moduleId: this.$route.query.moduleId,
          // moduleId: this.$route.query.moduleId||localStorage.getItem('moduleId'),
          id: item.id,
        }
      }
      this.reload()
      this.$router.push({
        name: name,
        query: query,
      })
    },
  },
  created() {
    this.init()
  },

  watch: {
    $route: {
      deep: true,
      // immediate: true,
      handler(val) {
        this.init()
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.nodaimg-box {
  margin-top: 20px;
}
</style>