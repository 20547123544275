<!--
 * @Author: 蒋威
 * @Date: 2022-05-24 14:02:32
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-07-05 08:36:30
 * @Description: 公司资讯
-->
<template>
  <div>
    <div class="realtimeInfo-container" v-if="list.length > 0">
      <div class="response">
        <div class="realtimeInfo-wrap">
          <template v-for="(item, index) in list">
            <div
              class="realtimeInfo-item pointer"
              :key="index"
              @click="toDetails(item)"
            >
              <div class="item-img-box">
                <img v-lazy="item.fileUrl" alt="" />
              </div>
              <div class="item-info-box">
                <h3 class="ellipsis" :title="item.title">{{ item.title }}</h3>
                <p class="ellipsis-2">{{ item.description }}</p>
              </div>
              <div class="item-time-box">
                <h4>
                  {{ $getDate(item.publishTime||item.createdAt, 'MD') }}
                </h4>
                <span>
                  {{ $getDate(item.publishTime||item.createdAt, 'Y') }}
                </span>
              </div>
            </div>
          </template>
        </div>
        <Page
          :page-size="form.limit"
          :total="total"
          :current-page="form.page"
          @currentChange="currentChange"
          v-if="totalPage > 1"
        ></Page>
        <div v-if="totalPage == 1" class="loadcomplete">没有更多了~</div>
      </div>
    </div>
    <div class="nodaimg-box" v-if="isNodata && this.list.length < 1">
      <img src="@/assets/img/nodata.png" alt="" />
    </div>
  </div>
</template>

<script>
import { detailsByNewsId, queryByChannelId } from '@/api/news/index.js'

export default {
  name: 'RealtimeInfo',
  data() {
    return {
      list: [
        // {
        //   title: '教育基金会信息管理系统',
        //   decs: '2020年10月10日-13日，隰县校长第⼆期⾻⼲团队幸福研修暨校长⾃修共读活动在隰县第⼀⼩学开展，28位校长和122位骨干教师参加本次活动...',
        //   date: '2022-02-01 16:00:21',
        //   file_url:
        //     'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        // },
      ],
      id: '',
      total: 0,
      totalPage: 0,
      form: {
        limit: 10,
        page: 1,
      },
      isNodata: false,
      // 是否是返回页面 ，如果是 执行mounted里面的方法
      isGoback: true,
    }
  },
  methods: {
    toDetails(item) {
      this.$emit('toDetail', item)
    },
    rest() {
      this.isNodata = false
      this.total = 0
      this.totalPage = 0
      this.form = {
        limit: 10,
        page: 1,
      }
      this.queryByChannelId()
    },
    async queryByChannelId() {
      const res = await queryByChannelId({ id: this.id, ...this.form })

      if (res.code === this.$httpCode) {
        this.list = res.data.newsPage?.list || []
        this.total = res.data.newsPage?.totalCount || 0
        this.totalPage = res.data.newsPage?.totalPage || 0
        if (this.total == 0) {
          this.isNodata = true
        } else {
          this.isNodata = false
        }
      } else {
        this.isNodata = true
        this.list = []
        this.total = 0
        this.totalPage = 0
      }
    },
    currentChange(val) {
      this.form.page = val
      this.queryByChannelId()
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isGoback) {
        this.isNodata = false
        this.id = this.$route.query.cate_id || 0
        this.rest()
      }
    })
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.realtimeInfo-container {
  background-color: #fff;
}
.response {
  padding: 10px 0 80px 0;
  .realtimeInfo-wrap {
    .realtimeInfo-item {
      width: 100%;
      height: 200px;
      margin-top: 20px;
      display: flex;
      overflow: hidden;
      position: relative;
      .item-img-box {
        height: 100%;
        flex: 0 0 360px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        }
      }
      .item-info-box {
        flex: 1;
        padding: 20px 30px 0 30px;
        overflow: hidden;
        h3 {
          height: 48px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 24px;
          color: #202020;
        }
        p {
          margin-top: 12px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 24px;
          color: #71767d;
        }
      }
      .item-time-box {
        flex: 0 0 130px;
        margin: 25px 0;
        border-left: 1px solid #e8e8e8;
        padding-left: 30px;
        h4 {
          height: 24px;
          font-size: 20px;
          font-family: DIN;
          font-weight: bold;
          line-height: 32px;
          color: #202020;
        }
        span {
          height: 16px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 32px;
          color: #71767d;
        }
      }

      &::after {
        content: '';
        height: 0;
        display: none;
        background-color: rgba(248, 248, 248, 0.39);
        position: absolute;
        top: 0px;
        width: 360px;
        left: 0;
        opacity: 0;
        transition: all 0.3s ease 0s;
      }
      &:hover {
        background-color: rgba(248, 248, 248, 0.39);
        box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
        img {
          transform: scale(1.1, 1.1);
        }

        .item-info-box {
          h3 {
            color: #39957a;
          }
        }
        &::after {
          opacity: 1;
          height: 200px;
        }
      }
    }
  }

  .more-btn {
    text-align: center;
    margin-top: 60px;
    span {
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #39957a;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
@media only screen and (min-width: 941px) and (max-width: 1299px) {
  .response {
    padding: 10px 0 80px 0;
    .realtimeInfo-wrap {
      .realtimeInfo-item {
        width: 100%;
        height: 180px;
        margin-top: 20px;
        display: flex;
        overflow: hidden;
        position: relative;
        .item-img-box {
          height: 100%;
          flex: 0 0 288px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          }
        }
        .item-info-box {
          flex: 1;
          padding: 20px 30px 0 30px;
          overflow: hidden;
          h3 {
            height: 48px;
            font-size: 18px;
            line-height: 24px;
          }
          p {
            margin-top: 12px;
            font-size: 16px;
            line-height: 24px;
          }
        }
        .item-time-box {
          flex: 0 0 130px;
          margin: 25px 0;
          padding-left: 30px;
          h4 {
            height: 24px;
            font-size: 20px;
            line-height: 32px;
          }
          span {
            height: 16px;
            font-size: 16px;
            line-height: 32px;
          }
        }
        &:hover {
          background-color: rgba(248, 248, 248, 0.39);
          box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
          img {
            transform: scale(1.1, 1.1);
          }

          .item-info-box {
            h3 {
              color: #39957a;
            }
          }
          &::after {
            opacity: 1;
            height: 200px;
          }
        }
      }
    }

    .more-btn {
      text-align: center;
      margin-top: 60px;
      span {
        height: 16px;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 940px) {
  .response {
    .realtimeInfo-wrap {
      .realtimeInfo-item {
        height: 120px;
        .item-img-box {
          height: 100%;
          flex: 0 0 216px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          }
        }
        .item-info-box {
          padding: 14px 20px 0 20px;
          h3 {
            height: 28px;
            font-size: 16px;
            line-height: 20px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
        .item-time-box {
          padding-left: 20px;
          margin: 20px 0;
          flex: 0 0 100px;
          h4 {
            font-size: 18px;
            margin-bottom: 6px;
            margin-top: 6px;
          }
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
  .loadcomplete {
    margin-top: $fontSize_16;
  }
}
@media only screen and (max-width: 768px) {
  .response {
    padding: 10px 10px 40px 0;
    .realtimeInfo-wrap {
      display: flex;
      flex-wrap: wrap;
      .realtimeInfo-item {
        height: auto;
        flex: 0 0 49%;
        margin-right: 2%;
        display: block;
        .item-img-box {
          height: auto;
          width: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
            transition: none;
          }
        }
        .item-info-box {
          padding: 10px 10px 0 10px;
          background-color: #f8f8f8;
          h3 {
            height: 24px;
            font-size: 16px;
            line-height: 20px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            margin-top: 10px;
          }
        }
        .item-time-box {
          background-color: #f8f8f8;
          margin: 0px 0;
          padding: 10px 0 10px 10px ;
          border: none;
          display: inline-flex;
          width: 100%;
          h4 {
            font-size: 16px;
            margin-bottom: 6px;
            height: 20px;
            line-height: 20px;
            margin-top: 0;
            margin-right: 5px;
          }
          span {
            font-size: 14px;
            height: 20px;
            line-height: 20px;
          }
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>