<!--
 * @Author: 蒋威
 * @Date: 2022-05-24 17:04:45
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-06-16 16:28:16
 * @Description: 合作伙伴
-->
<template>
  <div>
    <div class="response partner-container" v-show="list.length > 0">
      <div class="topicon-box">
        <img src="@/assets/img/partner.png" alt="" />
      </div>
      <h3 class="partner-title">{{ title }}</h3>
      <div class="partner-wrap">
        <template v-for="(item, index) in list">
          <div class="partner-item" :key="index" :title="item.name">
            <div class="item-box">
              <img v-lazy="item.fileUrl" alt="" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 没有数据 -->
    <div class="nodaimg-box" v-if="isNodata && this.list.length < 1">
      <img src="@/assets/img/nodata.png" alt="" />
    </div>
  </div>
</template>

<script>
import { queryByChannelId } from '@/api/news/index.js'
export default {
  name: 'CooperativePartner',
  data() {
    return {
      title: '合作伙伴',
      id: '',
      list: [
        // {
        //   file_url:
        //     'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        // },
      ],
      // 没有数据
      isNodata: false,
    }
  },
  methods: {
    async queryByChannelId() {
      const res = await queryByChannelId({ id: this.id, limit: 99999, page: 1 })
      if (res.code === this.$httpCode) {
        this.list = res.data.partner?.list || []
        if (this.list.length < 1) {
          this.isNodata = true
        } else {
          this.isNodata = false
        }
      } else {
        this.list = []
        this.isNodata = true
      }
    },
    init() {
      this.isNodata = false
      this.queryByChannelId()
    },
  },
}
</script>

<style  lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.partner-container {
  padding: 70px 0 70px 0;
  .partner-title {
    margin: 50px 0 60px 0;
    text-align: center;
    height: 42px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 42px;
    color: #202020;
  }
  .topicon-box {
    text-align: center;
    margin-bottom: 50px;
    img {
      width: 121px;
      height: 100px;
    }
  }
  .partner-wrap {
    display: flex;
    flex-wrap: wrap;
    .partner-item {
      flex: 0 0 calc(20% - 20px);
      -ms-flex: 0 0 218px;
      height: 108px;
      border: 1px solid #ececec;
      opacity: 1;
      border-radius: 12px;
      margin-right: 25px;
      padding: 10px;
      margin-bottom: 25px;
      transition: all 0.3s;
      cursor: pointer;
      text-align: center;
      overflow: hidden;
      .item-box {
        display: inline-block;
        // width: 100%;
        overflow: hidden;
        width: 100%;
        height: 84px;
        text-align: center;
        img {
          width: auto;
          height: 100%;
        }
      }
      &:nth-child(5n) {
        margin-right: 0;
      }
      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
      }
    }
  }
}
@media only screen and (min-width: 941px) and (max-width: 1299px) {
  .partner-container {
    padding: 70px 0 70px 0;
    .partner-title {
      margin: 50px 0 50px 0;
      text-align: center;
      height: 42px;
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 42px;
      color: #202020;
    }
    .topicon-box {
      text-align: center;
      margin-bottom: 50px;
      img {
        width: 121px;
        height: 100px;
      }
    }
    .partner-wrap {
      display: flex;
      flex-wrap: wrap;
      .partner-item {
        flex: 0 0 18%;
        -ms-flex: 0 0 15%;
        height: auto;
        border: 1px solid #ececec;
        opacity: 1;
        border-radius: 12px;
        margin-right: 2.5%;
        padding: 10px;
        margin-bottom: 25px;
        transition: all 0.3s;
        cursor: pointer;
        text-align: center;
        overflow: hidden;
        .item-box {
          display: inline-block;
          overflow: hidden;
          width: 100%;
          height: auto;
          text-align: center;
          img {
            width: 100%;
            height: auto;
          }
        }
        &:nth-child(5n) {
          margin-right: 0;
        }
        &:hover {
          transform: translateY(-10px);
          box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
        }
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 940px) {
  .partner-container {
    padding: 50px 0 50px 0;
    .partner-title {
      margin: 40px 0 40px 0;
      text-align: center;
      height: 42px;
      font-size: 28px;
      line-height: 42px;
    }
    .topicon-box {
      text-align: center;
      margin-bottom: 40px;
      img {
        width: 121px;
        height: 100px;
      }
    }
    .partner-wrap {
      display: flex;
      flex-wrap: wrap;
      .partner-item {
        flex: 0 0 32%;
        -ms-flex: 0 0 28%;
        height: auto;
        border: 1px solid #ececec;
        border-radius: 8px;
        margin-right: 2%;
        padding: 10px;
        margin-bottom: 25px;
        transition: all 0.3s;
        cursor: pointer;
        flex-wrap: wrap;
        text-align: center;
        .item-box {
          display: inline-block;
          overflow: hidden;
          width: 100%;
          height: auto;
          text-align: center;
          img {
            width: 100%;
            height: auto;
          }
        }
        &:nth-child(5n) {
          margin-right: 2%;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px){
  .partner-container {
    padding: 40px 0 40px 0;
    .partner-title {
      margin: 20px 0 30px 0;
      text-align: center;
      height: 42px;
      font-size: 28px;
      line-height: 42px;
    }
    .topicon-box {
      text-align: center;
      margin-bottom: 20px;
      img {
        width: 121px;
        height: 100px;
      }
    }
    .partner-wrap {
      display: flex;
      flex-wrap: wrap;
      .partner-item {
        flex: 0 0 49%;
        -ms-flex: 0 0 45%;
        height: auto;
        border-radius: 8px;
        margin-right: 2%;
        padding: 10px;
        margin-bottom: 20px;
        transition: all 0.3s;
        cursor: pointer;
        flex-wrap: wrap;
        text-align: center;
        .item-box {
          display: inline-block;
          overflow: hidden;
          width: 100%;
          height: auto;
          text-align: center;
          img {
            width: 100%;
            height: auto;
          }
        }
        &:nth-child(5n),
        &:nth-child(3n)
         {
          margin-right: 2%;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>