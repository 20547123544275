<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 17:07:15
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-27 09:47:15
 * @Description: 服务
-->
<template>
  <div class="response">
    <div class="service-container">
      <!-- 服务详情 -->
      <RichText ref="richTextViewRef" :id="id" />
      <!-- 运营服务 -->
      <ServeList ref="serveListViewRef" />
    </div>
  </div>
</template>

<script>
import ServeList from '@/components/ServeList'
import RichText from '@/components/RichText.vue'
export default {
  components: {
    ServeList,
    RichText,
  },
  props: {
    id: {
      type: String | Array,
    },
  },
  data() {
    return {
      serviceList: [],
      serviceInfo: {},
    }
  },
  methods: {
    init() {
      this.$nextTick(() => {
        if (this.$refs.richTextViewRef && this.$refs.serveListViewRef) {
          
      this.$refs.richTextViewRef.article = this.serviceInfo
      this.$refs.serveListViewRef.list = this.serviceList
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.service-container {
  padding-top: 60px;
}
</style>