<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 16:15:21
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-06-16 16:37:08
 * @Description: 产品列表
-->
<template>
  <div class="list-container">
    <div class="list-wrap">
      <template v-for="(item, index) in list">
        <div class="list-item pointer" :key="index" @click="toDetails(item)">
          <div class="response" :key="index">
            <div class="item-box">
              <div class="item-left-box">
                <img v-lazy="item.fileUrl" alt="" />
              </div>
              <!-- {{item}} -->
              <div class="item-right-box">
                <h3 class="ellipsis" :title="item.title">{{ item.title }}</h3>
                <p class="ellipsis-5">{{ item.description }}</p>
                <div class="details" @click="toDetails(item)">查看详情</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <Page
      :page-size="form.limit"
      :total="total"
      :current-page="form.page"
      @currentChange="currentChange"
      v-if="totalPage > 1"
    ></Page>
    <div v-if="totalPage == 1" class="loadcomplete">没有更多了~</div>
    <div class="nodaimg-box" v-if="isNodata">
      <img src="@/assets/img/nodata.png" alt="" />
    </div>
  </div>
</template>

<script>
import { detailsByNewsId, queryByChannelId } from '@/api/news/index.js'
export default {
  data() {
    return {
      list: [
        // {
        //   title: '教育基金会信息管理系统',
        //   decs: '普益云教育基金会信息管理系统是根据教育基金会业务特点及相关法规标准研发的专业公益信息化管理工具。系统包含新闻管理、项目管理、申报管理、捐赠管理等日常管理功能，并提供互联网传播及筹款工具。系统将基金会的捐赠人、公益项目、受益人等数据一一对应并一体化管理，形成清晰的项目数据链，使基金会管理更高效、流程更清晰、数据更透明，助力基金会高质量发展。',
        //   time: '2022-04-20',
        //   file_url:
        //     'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        // },
      ],
      id: '',
      total: 0,
      totalPage: 0,
      form: {
        limit: 10,
        page: 1,
      },
      isNodata: false,
      // 是否是返回页面 ，如果是 执行mounted里面的方法
      isGoback: true,
    }
  },
  methods: {
    toDetails(item) {
      this.$emit('toDetail', item)
    },
    rest() {
      this.isNodata = false
      this.total = 0
      this.totalPage = 0
      this.form = {
        limit: 10,
        page: 1,
      }
      this.queryByChannelId()
    },
    async queryByChannelId() {
      const res = await queryByChannelId({ id: this.id, ...this.form })

      if (res.code === this.$httpCode) {
        this.list = res.data.newsPage?.list || []
        this.total = res.data.newsPage?.totalCount || 0
        this.totalPage = res.data.newsPage?.totalPage || 0
        if (this.total == 0) {
          this.isNodata = true
        } else {
          this.isNodata = false
        }
      } else {
        this.isNodata = true
        this.list = []
        this.total = 0
        this.totalPage = 0
      }
    },
    currentChange(val) {
      this.form.page = val
      this.queryByChannelId()
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isGoback) {
        this.isNodata = false
        this.id = this.$route.query.cate_id || 0
        this.rest()
      }
    })
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.list-container {
  padding-bottom: 30px;
  .list-wrap {
    margin-top: 20px;
  }
  .list-item {
    padding: 70px 0;
    .item-box {
      height: 320px;
      display: flex;
      .item-left-box {
        width: 520px;
        flex: 0 0 520px;
        height: 320px;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        }
      }
      .item-right-box {
        margin-left: 100px;
        flex: 1;
        overflow: hidden;
        h3 {
          margin-top: 10px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          height: 70px;
          line-height: 40px;
          color: #202020;
        }
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 30px;
          color: #202020;
          height: 150px;
        }
        .details {
          margin-top: 30px;
          height: 30px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 30px;
          color: #39957a;
          text-decoration: underline;
        }
      }
    }
    &:nth-child(2n + 1) {
      background-color: #fff;
    }
    &:nth-child(2n + 2) {
    }
    &:hover {
      // background-color: #F8F8F8;
        background-color: rgba(248, 248, 248, 0.39);
      box-shadow: 0px 12px 64px rgba(0, 0, 0, 0.1);
      .item-left-box {
        img {
          transform: scale(1.1, 1.1);
        }
      }
      .item-right-box {
        h3 {
          color: #39957a;
        }
        p {
        }
        .details {
        }
      }
    }
  }
}
@media only screen and (min-width: 941px) and (max-width: 1299px) {
  .list-container {
    padding-bottom: 30px;
    .list-wrap {
      margin-top: 20px;
    }
    .list-item {
      padding: 70px 0;
      .item-box {
        height: 256px;
        display: flex;
        .item-left-box {
          width: 416px;
          flex: 0 0 416px;
          height: 256px;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          }
          &:hover {
            img {
              transform: scale(1.1, 1.1);
            }
          }
        }
        .item-right-box {
          margin-left: 50px;
          flex: 1;
          overflow: hidden;
          h3 {
            font-size: 24px;
            height: 60px;
            line-height: 40px;
          }
          p {
            font-size: 16px;
            line-height: 24px;
            height: 120px;
          }
          .details {
            margin-top: 20px;
            height: 30px;
            font-size: 16px;
            line-height: 30px;
            text-decoration: underline;
          }
        }
      }
      &:nth-child(2n + 1) {
        // background-color: #fff;
      }
      &:nth-child(2n + 2) {
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 940px) {
  .list-container {
    .list-wrap {
      margin-top: 0px;
    }
    .list-item {
      padding: 30px 0;
      .item-box {
        height: 192px;
        .item-left-box {
          width: 312px;
          flex: 0 0 312px;
          height: 192px;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          }
          &:hover {
            img {
              transform: none;
            }
          }
        }
        .item-right-box {
          margin-left: 40px;
          flex: 1;
          h3 {
            font-size: 18px;
            height: 50px;
            line-height: 32px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            color: #202020;
            height: 90px;
          }
          .details {
            margin-top: 16px;
            height: 30px;
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
      &:nth-child(2n + 1) {
        // background-color: #fff;
      }
      &:nth-child(2n + 2) {
        // background-color: #f5f5f5;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .list-container {
    padding-bottom: 20px;
    .list-wrap {
      margin-top: 0px;
    }
    .list-item {
      padding: 10px 0;
      margin-bottom: 10px;
      .item-box {
        // display: block;
        height: 96px;
        .item-left-box {
          width: 100%;
          flex: 0 0 156px;
          height: 96px;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            transition: none;
          }
          &:hover {
            img {
              transform: none;
            }
          }
        }
        .item-right-box {
          margin-left: 10px;
          flex: 1;
          h3 {
            margin-top: 4px;
            font-size: 14px;
            height: 22px;
            line-height: 20px;
          }
          p {
            font-size: 14px;
            line-height: 16px;
            color: #202020;
            height: 48px;
            -webkit-line-clamp: 3;
          }
          .details {
            margin-top: 4px;
            height: 16px;
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
      &:nth-child(2n + 1) {
        // background-color: #fff;
      }
      &:nth-child(2n + 2) {
        // background-color: #f5f5f5;
      }
    }
  }
}
</style>