<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 17:12:58
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-06-17 10:54:43
 * @Description: 运营服务：服务列表
-->
<template>
  <div class="response">
    <div class="service-container" v-show="list.length > 0">
      <div class="topicon-box">
        <img src="@/assets/img/operate.png" alt="" />
      </div>
      <h3 class="service-title">{{ title }}</h3>
      <div class="service-wrap">
        <template v-for="(item, index) in list">
          <div class="service-item" :key="index">
            <div class="service-icon-box">
              <div class="service-iconinfo">
                <img v-lazy="item.fileUrl" alt="" />
                <!-- <i class="iconfont icon-zhuyaorenyuan"></i> -->
              </div>
            </div>
            <div class="service-item-title ellipsis-2">
              {{ item.servicesName }}
            </div>
            <div class="service-item-decs ellipsis-6">
              {{ item.serviceContent }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      title: '运营服务',
      list: [
        // {
        //   title: '传播运营',
        //   decs: '传播运营以内容制作及宣传策划为核心，通过线下宣传、线上推广等方式，向政府部门、行业机构、社会公众宣传推广公益品牌及公益项目，扩大社会影响力，增强品牌美誉度。',
        // },
      ],
    }
  },
}
</script>

<style  lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.service-container {
  margin-bottom: 40px;
  .topicon-box {
    text-align: center;
    margin-bottom: 50px;
    img {
      width: 116px;
      height: 98px;
    }
  }
  .service-title {
    height: 42px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 42px;
    color: #202020;
    text-align: center;
    margin-bottom: 90px;
  }
  .service-wrap {
    display: flex;
    flex-wrap: wrap;
    .service-item {
      height: 480px;
      margin-right: 20px;
      // flex: 0 0 420px;
      flex: 0 0 calc(33.33% - 14px);
      -ms-flex: 0 0 300px;
      margin-bottom: 20px;
      background-color: #f5f5f5;
      padding: 0 60px;
      transition: all 0.3s;
      overflow: hidden;
      .service-icon-box {
        margin-top: 60px;
        text-align: center;
        .service-iconinfo {
          width: 80px;
          height: 80px;
          display: inline-block;
          border: 1px solid #39957a;
          border-radius: 50%;
          text-align: center;
          line-height: 80px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
          i {
            font-size: 40px;
            color: #39957a;
          }
        }
      }
      .service-item-title {
        margin-top: 20px;
        height: 48px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
      }
      .service-item-decs {
        margin-top: 10px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 32px;
        color: #202020;
        text-align: center;
      }
      &:nth-child(3n) {
        margin: 0;
      }
      &:nth-child(3n + 1) {
        background-color: #f5f5f5;
      }
      &:nth-child(3n + 2) {
        background: linear-gradient(to bottom, #6ecee2, #55d0b0, #45d18f);
        .service-item-title,
        .service-item-decs {
          color: #fff;
        }
        .service-icon-box {
          .service-iconinfo {
            border: 1px solid #fff;
            i {
              color: #fff;
            }
          }
        }
      }
      &:nth-child(3n + 3) {
        background-color: #f5f5f5;
      }
      &:hover {
        transform: translateY(-10px);
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 940px) {
  .service-container {
    margin-bottom: 40px;
    .topicon-box {
      text-align: center;
      margin-bottom: 30px;
      img {
        width: 116px;
        height: 98px;
      }
    }
    .service-title {
    }
    .service-wrap {
      display: flex;
      flex-wrap: wrap;
      .service-item {
        padding: 0 10px;
        overflow: hidden;
        -ms-flex: 0 0 30%;
        .service-icon-box {
          .service-iconinfo {
            width: $fontSize_40;
            height: $fontSize_40;
            border: 1px solid #39957a;
            line-height: $fontSize_40;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .service-container {
    margin-bottom: 30px;
    .topicon-box {
      text-align: center;
      margin-bottom: 30px;
      img {
        width: 116px;
        height: 98px;
      }
    }
    .service-title {
      margin-bottom: 30px;
    }
    .service-wrap {
      display: block;
      flex-wrap: wrap;
      .service-item {
        height: auto;
        padding: 0 10px;
        margin-right: 0;
        overflow: hidden;
        .service-icon-box {
          margin-top: 20px;
          .service-iconinfo {
            width: $fontSize_40;
            height: $fontSize_40;
            border: 1px solid #39957a;
            line-height: $fontSize_40;
          }
        }
        .service-item-title {
          height: 36px;
        }
        .service-item-decs{
          padding-bottom: 20px;
        }
      }
    }
  }
}
</style>