var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Banner',{attrs:{"bannerList":_vm.bannerList}}),(!this.$route.query.id)?_c('NavBox',{ref:"NavBoxRef",attrs:{"menuList":_vm.menuList},on:{"changeMenu":_vm.changeMenu}}):_vm._e(),(this.$route.name != 'home' && this.$route.query.id)?_c('Breadcrumb',{ref:"Breadcrumb",attrs:{"firstMenu":_vm.firstMenu,"currentMenu":_vm.currentMenu}}):_vm._e(),(
      !this.$route.query.id &&
      _vm.model == 'news' &&
      _vm.showModel == 1 &&
      ['list', 'all'].includes(_vm.menuType)
    )?[_c('ProductList',{ref:"productListRef",on:{"toDetail":_vm.toDetail}})]:_vm._e(),(
      !this.$route.query.id &&
      _vm.model == 'news' &&
      _vm.showModel == 2 &&
      ['list', 'all'].includes(_vm.menuType)
    )?[_c('ProductCase',{ref:"productCaseRef",on:{"toDetail":_vm.toDetail}})]:_vm._e(),(
      this.$route.query.id &&
      _vm.model == 'news' &&
      [1, 2, 3].includes(_vm.showModel) &&
      ['list', 'all'].includes(_vm.menuType)
    )?[_c('RichText',{ref:"productRichText",attrs:{"id":_vm.detailsId}})]:_vm._e(),(
      !this.$route.query.id &&
      _vm.model == 'news' &&
      [1, 2, 3].includes(_vm.showModel) &&
      ['info'].includes(_vm.menuType)
    )?[_c('ProductDetails',{ref:"ProductCaseinfoRef",attrs:{"id":_vm.detailsId}})]:_vm._e(),(
      this.$route.query.id &&
      _vm.model == 'news' &&
      [1, 2, 3].includes(_vm.showModel) &&
      ['info'].includes(_vm.menuType)
    )?[_vm._m(0)]:_vm._e(),(
      !this.$route.query.id &&
      _vm.model == 'news' &&
      _vm.showModel == 3 &&
      ['list', 'all'].includes(_vm.menuType)
    )?[_c('RealtimeInfo',{ref:"RealtimeInfoRef",on:{"toDetail":_vm.toDetail}})]:_vm._e(),(_vm.model == 'other' && ['company'].includes(_vm.menuType))?[_c('Qualifications',{ref:"qualificationsRef"})]:_vm._e(),(_vm.model == 'other' && ['contactUs'].includes(_vm.menuType))?[_c('ContactUs',{ref:"contactUsRef"})]:_vm._e(),(_vm.model == 'other' && ['partner'].includes(_vm.menuType))?[_c('CooperativePartner',{ref:"cooperativePartnerRef"})]:_vm._e(),(_vm.isNodata)?_c('div',{staticClass:"nodaimg-box"},[_c('img',{attrs:{"src":require("@/assets/img/nodata.png"),"alt":""}})]):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nodaimg-box"},[_c('img',{attrs:{"src":require("@/assets/img/nodata.png"),"alt":""}})])}]

export { render, staticRenderFns }