<!--
 * @Author: 蒋威
 * @Date: 2022-05-24 11:22:29
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-27 10:45:23
 * @Description: 案列详情
-->
<template>
  <div class="productDetails-wrap">
    <div class="response">
      <div class="productDetails-container">
        <!-- <h3 class="product-title">{{ article.title }}</h3>
        <div class="product-tips">
          <span>发布时间：{{$getDate(article.createdAt,'YMD')}}</span>
          <span>浏览量：{{ article.pageView }}</span>
        </div> -->
        <RichText ref="productDetailsTextRef" :id="id" />
      </div>
    </div>
  </div>
</template>

<script>
import { detailsByNewsId } from '@/api/news/index.js'
import RichText from '@/components/RichText.vue'
export default {
  name: 'productDetails',
  props: {
    id: {
      type: String | Array,
    },
  },
  components: {
    RichText,
  },
  data() {
    return {
      article: {
        title: '顺丰公益基金会',
        decs: '',
        time: '2020-08-24 09:25',
        Views: 45875,
      },
    }
  },
  methods: {
    async init(data) {
      console.log(data,'data')
      this.$nextTick(() => {
        // 将数据传给子组件
        this.$refs.productDetailsTextRef.setInit(data)
      })
    },
  },
}
</script>

<style  lang="scss" scoped>
.productDetails-wrap {
  background: #ffffff;
  padding-bottom: 20px;
}
.productDetails-container {
  // padding: 60px 50px;
  background-color: #fff;
  .product-title {
    height: 38px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 42px;
    color: #202020;
    text-align: center;
  }
  .product-tips {
    margin-top: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #f5f5f5;
    text-align: center;
    span {
      margin-right: 10px;
      height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: #202020;
    }
  }
}
</style>